import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash';
import router from '@/router'
import { db } from '@/services/firebase/index.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    scoresDbName: 'scores',
    categoryDbName: 'categories',
    isJudge: false,
    collectionNames: [],
    userCollection: null
  },
  mutations: {
    setIsJudge(state, event) {
      state.isJudge = event;
    },
    setScoreDbName(state, name) {
      state.scoresDbName = name;
    },
    setCategoryDbName(state, name) {
      state.categoryDbName = name;
    },
    setCollectionNames(state, names) {
      state.collectionNames = names;
    },
    setUserCollection(state, collection) {
      state.userCollection = collection;
    },
    clearStore(state) {
      state.scoresDbName = 'scores';
      state.categoryDbName = 'categories';
      state.isJudge = false;
      state.collectionNames = [];
      state.userCollection = null;
    }
  },
  actions: {
    setUpApplication(_ref, uid) {
      var dispatch = _ref.dispatch;
      dispatch('setIsJudge', uid)
      .then(result => {
        console.log(result);
        dispatch('setCollectionNames')
        .then(result => {
          console.log(result);
          dispatch('getCollectionNamesByUser', uid)
          .then(result => {
            console.log(result);
            router.push('/home').catch(() => {});
          })
        })
      })
    },
    setIsJudge( _ref, uid) {
      var commit = _ref.commit;
      return new Promise((resolve) => {
          var judgeList = db.collection('judges');
          var judges = [];
          judgeList.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              var docData = doc.data();
              judges.push(docData);
            });
            var isJudge = _.some(judges, { 'userId': uid });
            commit('setIsJudge', isJudge);
          }).catch(function(error) {
              console.log(error);
          });
          resolve('I set the admin');
      });
    },
    setDatabaseNames(_ref, payload) {
      var commit = _ref.commit;
      commit('setScoreDbName', payload.Scores);
      commit('setCategoryDbName', payload.Category);
    },
    toggleAdmin(_ref, value) {
      var commit = _ref.commit;
      commit('setIsJudge', value);
    },
    setCollectionNames(_ref) {
      var commit = _ref.commit;
      var beginningDate = Date.now() - 604800000;
      var todaysDateObject = new Date(beginningDate);
      return new Promise((resolve) => {
          var collectionList = db.collection('collectName').where('activeTo', '>', todaysDateObject);
          var collections = [];
          collectionList.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              var docData = doc.data();
              collections.push(docData);
            });
            commit('setCollectionNames', collections);
          }).catch(function(error) {
              console.log(error);
          });
          resolve('I set the collection names');
      });
    },
    getCollectionNamesByUser(_ref, uid) {
      const commit = _ref.commit;
      const state = _ref.state;
      //var beginningDate = Date.now() - 604800000;
      //var todaysDateObject = new Date(beginningDate);
      return new Promise((resolve) => {
          const userCollection = db.collection('user_collection');
          userCollection.where('userId', '==', uid)
          //userCollection.where('activeTo', '>', todaysDateObject)
                      .limit(1)
                      .get()
                        .then(function(querySnapshot) {
                          if (querySnapshot.docs.length > 0) {
                            querySnapshot.forEach(function(doc) {
                              var docData = doc.data();
                              commit('setUserCollection', docData);
                              var collectionId = docData.collectionId;
                              var userCols = _.find(state.collectionNames, { 'Id': collectionId});
                              if (userCols) {
                                commit('setScoreDbName', userCols.Scores);
                                commit('setCategoryDbName', userCols.Categories);
                              }
                              resolve('I found collection names for user');
                            });
                          } else {
                            router.push('/home').catch(() => {});
                          }
                        }).catch(function(error) {
                            console.log(error);
                        });
      });
    },
    writeUserInfo(_ref, userInfo) {
      const commit = _ref.commit;
      db.collection('user_collection').add(userInfo).then(() => {
          commit('setUserCollection', userInfo);
      }).catch((error) => {
          alert(error);
      });
    },
    clearStore(_ref) {
      const commit = _ref.commit;
      commit('clearStore');
    }
  },
  getters: {
    getScoresDatabaseName: function(state) {
        return state.scoresDbName;
    },
    getCategoryDatabaseName: function(state) {
      return state.categoryDbName;
    },
    getIsJudge: function(state) {
      return state.isJudge;
    },
    getCollectionNames: function(state) {
      return state.collectionNames;
    },
    getUserCollection: function(state) {
      return state.userCollection;
    }
  }
})
