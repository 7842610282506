import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/services/firebase/index.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../components/Login.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '../components/Signup.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot" */ '../components/ForgotPassword.vue')
  },
  {
    path: '/profile/:id',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../components/UserProfile'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/home',
    name: 'Score',
    component: () => import(/* webpackChunkName: "add" */ '../views/ScoreList'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add',
    name: 'New Score',
    component: () => import(/* webpackChunkName: "add" */ '../views/ScoreCreate'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/edit/:id',
    name: 'edit',
    component: () => import(/* webpackChunkName: "edit" */ '../views/ScoreEdit'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (auth.currentUser) {
      next();
    } else {
      alert('You must be logged in to see this page');
      next({
        path: '/',
      });
    }
  } else {
    next();
  }
});

export default router
