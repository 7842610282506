<template>
    <!-- Navigation -->
    <nav class="navbar shadow bg-white rounded justify-content-between flex-nowrap flex-row fixed-top">
      <div class="container" style="margin-right: 1px;">
        <a class="navbar-brand float-left fw-bolder">Show Results</a>
        <div>
          <select v-if="showSelection" v-model="currentDbId" id="dbName" class="form-select form-control form-control-sm">
            <option value=0>Please select event to view</option>
            <option v-for="name in collectionNames" v-bind:key="name.Id">
                {{name.Name}}
            </option>
          </select>
        </div>
        <ul class="nav navbar-nav flex-row float-right gap-2">
          <li v-if="isSignUp" class="nav-item">
            <router-link class="btn btn-outline-primary me-md-2" to="/" title="Sign Into App">Sign in</router-link>
          </li>
          <li v-if="isSignIn" class="nav-item">
            <router-link class="btn btn-outline-primary" to="/signup" title="Sign Up for Access">Sign up</router-link>
          </li>
          <li v-if="!isAuth && isJudge" class="nav-item">
            <router-link class="btn btn-outline-primary me-md-2" to="/add" title="Add new Score">Add</router-link>
          </li>
          <li v-if="!isAuth" class="nav-item">
            <button @click="()=>logOut()" class="btn btn-outline-secondary btm-sm" title="Log Out of App">Log out</button>
          </li>
        </ul>
      </div>
      <div v-if="user!=null" >
        <router-link class="material-icons person" :to="{name: 'profile', params: { id: user.uid }}" title="Update User Profile">person</router-link>
      </div>
    </nav>
</template>

<script>
  import { auth } from '@/services/firebase/index.js';
  export default  {
  name: 'navBar',
  data: function data() {
    return {
      currentDbId: 0,
      showSelection: false,
      user: ''
    };
  },
  methods: {
    logOut() {
        auth.signOut()
    }
  },
  computed: {
    isAuth() {
        return this.$route.name == 'signup' || this.$route.name == 'login' || this.$route.name == 'forgot-password';
    },
    isSignUp() {
        return this.$route.name == 'signup' || this.$route.name == 'forgot-password';
    },
    isSignIn() {
        return this.$route.name == 'login';
    },
    isJudge() {
      return this.$store.getters.getIsJudge;
    },
    collectionNames() {
      return this.$store.getters.getCollectionNames;
    }
  },
  created() {
      auth.onAuthStateChanged((user) => {
          if (user) {
              this.user = user;
          } else {
              this.user = null;
              this.$store.dispatch('clearStore');
              this.$router.push('/').catch(() => {});
          }
      });
      this.$store.dispatch('setCollectionNames');
  },
  mounted() {
    document.title = 'Car Show Scores';
  }
};
</script>

<style>
  .person {
      text-decoration: none;
      margin-right: 25px;
  }
</style>